.root {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap:  20px 15px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    gap: 35px 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
  margin-bottom: 40px;
  height:20px;
  
}
