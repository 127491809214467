@import '../../../styles/propertySets.css';

.root {
  display: none; /* Hide by default */
}

@media (max-width: 767px) {
  .root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--matterColorBright);
    position: sticky;
    top: 0;
    z-index: 1;
  }

}

.searchResultSummary {
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center; /* Vertically center align */
  background-color: var(--matterColorBright);


}

.buttons {
  display: flex;
  justify-content: space-between; /* Align to the right */
  align-items: center; /* Vertically center align */
  width: 100%;

}

.mobilepagetitlecontainer{

  margin: 0 -24px 0 -24px;



  @media (--viewportMedium) {
    display: none;
    }

    @media (--viewportLarge) {
      display: none;
      }
}

.mobileNoResult {
  @media (--viewportMedium) {
    display: none;
    }

    @media (--viewportLarge) {
      display: none;
      }
}

.resultsContainer{
  display: flex;
  align-items: center;
  flex-direction: row;

}

.resultsText{
  
  text-transform: uppercase;
  margin: auto;

  white-space: nowrap;
  font-size: clamp(3pt, 2.2vw, 8pt);
  margin-left: 0;

  @media (--viewportMedium) {
    display: none;
    }

    @media (--viewportLarge) {
      display: none;
      }


}

.titlepagecontainer{
  margin: 0 -24px 0 -24px;

}

.activeMarketTextContainer{
  display: flex;
  align-items: center;
  margin: 10px 0 -5px;


  @media (--viewportMedium) {
    display: none;
    }

    @media (--viewportLarge) {
      display: none;
      }

}

.activeMarketText{

  text-transform: uppercase;
  font-weight: bold;
  
  margin: auto;

  white-space: nowrap;
 
  font-size: clamp(8pt, 2.5vw, 10pt);

  @media (--viewportMedium) {
    display: none;
    }

    @media (--viewportLarge) {
      display: none;
      }


}


.filtersButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

 
  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  width: 50%;
  
  border-right: none;
  border-left: none;


}

.filtersButtonSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  width: 50%;
  
  border-right: none;
  border-left: none;

}

.mapIcon {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 94px 0;
  margin: 0 24px;
  
  @media (--viewportMedium) {
    display: none;
    }
}



.modalHeadingWrapper {
  padding-bottom: 31px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.modalHeading {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.resetAllButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin: 8px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
 
 
  @media (--viewportMedium) {
    display: none;
    }
  
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}
